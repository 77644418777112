import React from "react";
import "./More.scss";

const More = () => {
  return (
    <div className="more-container">
      <div className="more-parent">
        <div className="batman">I am Batman.</div>
      </div>
    </div>
  );
};

export default More;
